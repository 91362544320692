/*
 * @Author: 冯杰
 * @Date: 2021-08-05 11:34:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 21:14:50
 * 包量任务
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
// import Header from './table/components/header.vue';
import Table from './table/taget.vue';
import BtnCode from './table/components/btn.vue';

// formCreate.component('Header', Header);
formCreate.component('Table', Table);
formCreate.component('BtnCode', BtnCode);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'packageForm',
    };
  },
  methods: {
    formComplete() {
      const PackageForm = this.getRule('btncode');
      PackageForm.on = {
        ...PackageForm.on,
        change: (value) => {
          this.fApi.el('table').$refs.packgeTableRef.code = value;
        },
      };
    },
  },
  watch: {},
};
