<!--
 * @Author: 冯杰
 * @Date: 2021-08-07 10:17:11
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-08-07 14:16:26
 * @FilePath: \crm2.0\found\views\cps\views\execute\package\table\taget.vue
-->
<template>
    <div>
        <TablePage ref="packgeTableRef" />
    </div>
</template>

<script>
import TablePage from './index.js';

export default {
  components: {
    TablePage,
  },
};
</script>
