<!--
 * @Author: 冯杰
 * @Date: 2021-08-05 15:44:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-03 11:00:02
 * @FilePath: \crm2.0\found\views\cps\views\execute\package\table\components\btn.vue
-->
<template>
    <div>
        <el-radio-group v-model="redioValue" size="medium" class="flotLeft" @change="change">
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button label="notStarted">未开始</el-radio-button>
            <el-radio-button label="inProgress">执行中</el-radio-button>
            <el-radio-button label="dividedProfit">已分利</el-radio-button>
            <el-radio-button label="expired">已过期</el-radio-button>
        </el-radio-group>
        <!-- <div class="flotRight">
            <el-button size="medium" @click="shareProfit">分利</el-button>
            <el-button size="medium" @click="shareProfitAll">全部分利</el-button>
        </div> -->
    </div>
</template>

<script>
export default {
  data() {
    return {
      redioValue: 'all',
    };
  },
  methods: {
    change(val) {
      this.$emit('change', this.redioValue);
    },
    // shareProfit() {
    //   this.$emit('click', 'shareProfit');
    // },
    // shareProfitAll() {
    //   this.$emit('clickAll', 'shareProfitAll');
    // },
  },
};
</script>

<style lang="less" scoped>
.flotLeft{
    float: left;
}
.flotRight{
    margin-right: 60px;
    float: right;
    .el-button{
        background-color: #169BD5;
        color: #fff;
    }
}
</style>
