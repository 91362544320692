/*
 * @Author: 冯杰
 * @Date: 2021-08-05 09:47:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-10 10:19:39
 * 包量任务列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import ElCity from '../../../components/city.vue';
import Tree from './components/tree.vue';
import Record from './record-list.js';
import { unitConvert } from '../../../components/moneyNumber.js';

export default {
  name: 'package',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/quantifyPolicyReport/findByConditions',
      requestType: 'GET',
      params: {},
      isCalculateHeight: false,
    };
  },
  components: {
    Modal,
    ElCity,
    Tree,
    Record,
  },
  watch: {
    code(val, oldVal) {
      let parvalue = null;
      switch (this.code) {
        case 'all':
          parvalue = {};
          this.getData(parvalue);
          break;
        case 'notStarted':
          parvalue = {
            taskStatus: 0,
          };
          this.getData(parvalue);
          break;
        case 'inProgress':
          parvalue = {
            taskStatus: 1,
          };
          this.getData(parvalue);
          break;
        case 'dividedProfit':
          parvalue = {
            taskStatus: 2,
          };
          this.getData(parvalue);
          break;
        case 'expired':
          parvalue = {
            taskStatus: 3,
          };
          this.getData(parvalue);
          break;

        default:
          break;
      }
    },
  },
  methods: {
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'city') {
        item.typeName = 'ElCity';
        item.type = 'customSearch';
      }

      if (item.field === 'orgName') {
        item.typeName = 'Tree';
        item.type = 'customSearch';
      }
      return item;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'code-record') {
        this.modalConfig.title = '关联扫码记录';
        this.formName = 'Record';
        this.propsObjInData.id = row.id;
        this.formConfig.buttonCode = 'code-record';
        this.modalConfig.showFooter = true;
        this.modalConfig.buttons = {
          sure: false,
          close: true,
        };
        this.openModal();
      } else if (val.code === 'relevance-protocol' || val.code === 'view') {
        // 跳转分利协议模板
        this.$router.push({
          name: 'modules',
          params: {
            templateCode: row.templateId,
          },
        });
      }
    },
    cityData(val) {
      if (val) {
        this.searchFormData = {
          city: val,
        };
      }
    },
    getData(params) {
      request.get('/cps/v1/web/quantifyPolicyReport/findByConditions', params).then((res) => {
        if (!res.success) return;
        this.tableData = res.result.records;
      });
    },
    // setColumn(v) {
    //   const item = v;
    //   if (item.field === 'shareProfitType') {
    //     item.search = 'true';
    //     item.title = '分利类型';
    //     item.type = 'select';
    //     item.apiUrl = '/cps/v1/reward/mount/findReward';
    //     item.optionsKey = {
    //       label: 'name',
    //       value: 'id',
    //       children: 'children',
    //     };
    //     item.isSearch = true;
    //     item.paramsName = 'name';
    //   }
    //   return item;
    // },
    afterGetList() {
      const editTableData = this.tableData;
      editTableData.map((v) => {
        const item = v;
        if (item.reachRate === null) {
          item.reachRate = '';
        } else {
          item.reachRate += '%';
        }
        item.rewardTypeStatisticsVos.map((q) => {
          const money = q;
          if (money.key === 'redpacket') {
            item.redpacket = unitConvert(money.amount);
          }
          if (money.key === 'cost') {
            item.integral = unitConvert(money.amount);
          }
          return money;
        });
        return item;
      });
      this.tableData = editTableData;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('packageList');
  },
};
