/*
 * @Author: 冯杰
 * @Date: 2021-08-05 17:31:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 23:08:26
 * 关联扫码记录
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'RecordList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/scanCodeRecordReport/findByQuantifyTaskBusinessCode',
      requestType: 'GET',
      params: {
        quantifyTaskId: this.propsObj.id,
      },
    };
  },
  components: {
    Modal,
  },
  props: {
    propsObj: Object,
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'check-all') {
        // 全部全选
        this.$refs.xTable.setAllCheckboxRow(true);
      }
    },
    setColumn(col) {
      const item = col;
      if (item.field === 'checkTypeseq') {
        console.log(item);
        item.title = '序号';
        item.width = '50';
      }
      return item;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('codeRecord');
  },
};
